<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 22 }"
        :colon="false"
      >
        <a-form-item label="转移人">
          <EmployeeSelector
            :single="true"
            @change="
              (arr) => {
                if (arr.length > 0) {
                  oldAssignee = arr[0];
                  getTask(oldAssignee);
                } else {
                  oldAssignee = {};
                }
              }
            "
            :value="oldAssignee.name ? [oldAssignee] : []"
          >
            <a-button type="primary">
              {{ oldAssignee.name ? oldAssignee.name : "选择" }}
            </a-button>
          </EmployeeSelector>
        </a-form-item>

        <a-form-item label="接收人">
          <EmployeeSelector
            :single="true"
            @change="
              (arr) => {
                if (arr.length > 0) {
                  newAssignee = arr[0];
                } else {
                  newAssignee = {};
                }
              }
            "
            :value="newAssignee.name ? [newAssignee] : []"
          >
            <a-button type="primary">
              {{ newAssignee.name ? newAssignee.name : "选择" }}
            </a-button>
          </EmployeeSelector>
        </a-form-item>

        <a-form-item label="待办任务">
          <a-transfer
            :data-source="[...assigneeTask, ...candidateTask]"
            :rowKey="(record) => record.taskId"
            :target-keys="targetKeys"
            :show-search="true"
            :filter-option="
              (inputValue, item) => item.title.indexOf(inputValue) !== -1
            "
            :show-select-all="true"
            @change="onChange"
          >
            <template
              slot="children"
              slot-scope="{
                props: { filteredItems, selectedKeys },
                on: { itemSelectAll, itemSelect },
              }"
            >
              <a-table
                :row-selection="
                  getRowSelection({
                    selectedKeys,
                    itemSelectAll,
                    itemSelect,
                  })
                "
                :columns="columns"
                :data-source="filteredItems"
                size="small"
                :custom-row="
                  ({ key }) => ({
                    on: {
                      click: () => {
                        itemSelect(key, !selectedKeys.includes(key));
                      },
                    },
                  })
                "
              />
            </template>
          </a-transfer>
        </a-form-item>

        <a-form-item label="未到达任务">
          <a-transfer
            :data-source="todoTask"
            :rowKey="(record) => record.taskId"
            :target-keys="targetKeys2"
            :show-search="true"
            :filter-option="
              (inputValue, item) => item.title.indexOf(inputValue) !== -1
            "
            :show-select-all="true"
            @change="onChange2"
          >
            <template
              slot="children"
              slot-scope="{
                props: { filteredItems, selectedKeys },
                on: { itemSelectAll, itemSelect },
              }"
            >
              <a-table
                :row-selection="
                  getRowSelection({
                    selectedKeys,
                    itemSelectAll,
                    itemSelect,
                  })
                "
                :columns="columns"
                :data-source="filteredItems"
                size="small"
                :custom-row="
                  ({ key }) => ({
                    on: {
                      click: () => {
                        itemSelect(key, !selectedKeys.includes(key));
                      },
                    },
                  })
                "
              />
            </template>
          </a-transfer>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button type="primary" :loading="saveLoading" @click="save"
              >保存</a-button
            >
            <a-button @click="$router.go(-1)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
import EmployeeSelector from "@/components/employee-selector";
import difference from "lodash/difference";
function fetchTask(params) {
  return request({
    url: "/activiti-service/servicetask/user/relatedtasks",
    method: "get",
    params,
  });
}
function finish(data) {
  return request({
    url: "/activiti-service/servicetask/user/relatedtasks/transfer",
    method: "post",
    data,
  });
}

export default {
  components: {
    EmployeeSelector,
  },

  data() {
    return {
      oldAssignee: {},
      newAssignee: {},
      assigneeTask: [],
      candidateTask: [],
      todoTask: [],

      targetKeys: [],
      targetKeys2: [],

      columns: [
        {
          dataIndex: "title",
          title: "任务名",
        },
        {
          dataIndex: "nextAssignee",
          title: "待办人",
        },
        {
          dataIndex: "procStartTime",
          title: "提交时间",
        },
      ],

      saveLoading: false,
    };
  },

  methods: {
    getTask(user) {
      const hide = this.$message.loading("加载中...");
      this.list = [];
      this.assigneeTask = [];
      this.candidateTask = [];
      this.todoTask = [];

      fetchTask({
        assignee: user.userId,
      })
        .then((res) => {
          console.log("res", res);
          if (res && Array.isArray(res.assigneeTask)) {
            this.assigneeTask = res.assigneeTask;
          }
          if (res && Array.isArray(res.todoTask)) {
            this.todoTask = res.todoTask;
          }
          if (res && Array.isArray(res.candidateTask)) {
            this.candidateTask = res.candidateTask;
          }
        })
        .finally(() => {
          hide();
        });
    },

    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },

    onChange2(nextTargetKeys) {
      this.targetKeys2 = nextTargetKeys;
    },

    getRowSelection({ selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },

    save() {
      if (!this.oldAssignee.name) {
        this.$message.error("请选择转移人");
        return;
      }
      if (!this.newAssignee.name) {
        this.$message.error("请选择接收人");
        return;
      }

      if (this.targetKeys.length === 0 && this.targetKeys2.length === 0) {
        this.$message.error("请选择任务");
        return;
      }

      const arr = [];
      if (this.targetKeys.length > 0) {
        const result1 = [];
        const result2 = [];

        this.targetKeys.forEach((key) => {
          const item = this.assigneeTask.find((item) => item.taskId === key);
          const item2 = this.candidateTask.find((item) => item.taskId === key);

          if (item) {
            result1.push(key);
          } else if (item2) {
            result2.push(key);
          }
        });

        if (result1.length > 0) {
          arr.push({
            newAssignee: this.newAssignee.userId,
            newAssigneeName: this.newAssignee.name,
            oldAssignee: this.oldAssignee.userId,
            oldAssigneeName: this.oldAssignee.name,
            taskIds: result1,
            transferType: "assigneeTask",
          });
        }

        if (result2.length > 0) {
          arr.push({
            newAssignee: this.newAssignee.userId,
            newAssigneeName: this.newAssignee.name,
            oldAssignee: this.oldAssignee.userId,
            oldAssigneeName: this.oldAssignee.name,
            taskIds: result2,
            transferType: "candidateTask",
          });
        }
      }

      if (this.targetKeys2.length > 0) {
        arr.push({
          newAssignee: this.newAssignee.userId,
          newAssigneeName: this.newAssignee.name,
          oldAssignee: this.oldAssignee.userId,
          oldAssigneeName: this.oldAssignee.name,
          taskIds: this.targetKeys2,
          transferType: "todoTask",
        });
      }

      this.saveLoading = true;
      finish(arr).finally(() => {
        this.saveLoading = false;
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
