var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 2 },"wrapper-col":{ span: 22 },"colon":false}},[_c('a-form-item',{attrs:{"label":"转移人"}},[_c('EmployeeSelector',{attrs:{"single":true,"value":_vm.oldAssignee.name ? [_vm.oldAssignee] : []},on:{"change":(arr) => {
              if (arr.length > 0) {
                _vm.oldAssignee = arr[0];
                _vm.getTask(_vm.oldAssignee);
              } else {
                _vm.oldAssignee = {};
              }
            }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.oldAssignee.name ? _vm.oldAssignee.name : "选择")+" ")])],1)],1),_c('a-form-item',{attrs:{"label":"接收人"}},[_c('EmployeeSelector',{attrs:{"single":true,"value":_vm.newAssignee.name ? [_vm.newAssignee] : []},on:{"change":(arr) => {
              if (arr.length > 0) {
                _vm.newAssignee = arr[0];
              } else {
                _vm.newAssignee = {};
              }
            }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.newAssignee.name ? _vm.newAssignee.name : "选择")+" ")])],1)],1),_c('a-form-item',{attrs:{"label":"待办任务"}},[_c('a-transfer',{attrs:{"data-source":[..._vm.assigneeTask, ..._vm.candidateTask],"rowKey":(record) => record.taskId,"target-keys":_vm.targetKeys,"show-search":true,"filter-option":(inputValue, item) => item.title.indexOf(inputValue) !== -1,"show-select-all":true},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function({
              props: { filteredItems, selectedKeys },
              on: { itemSelectAll, itemSelect },
            }){return [_c('a-table',{attrs:{"row-selection":_vm.getRowSelection({
                  selectedKeys,
                  itemSelectAll,
                  itemSelect,
                }),"columns":_vm.columns,"data-source":filteredItems,"size":"small","custom-row":({ key }) => ({
                  on: {
                    click: () => {
                      itemSelect(key, !selectedKeys.includes(key));
                    },
                  },
                })}})]}}])})],1),_c('a-form-item',{attrs:{"label":"未到达任务"}},[_c('a-transfer',{attrs:{"data-source":_vm.todoTask,"rowKey":(record) => record.taskId,"target-keys":_vm.targetKeys2,"show-search":true,"filter-option":(inputValue, item) => item.title.indexOf(inputValue) !== -1,"show-select-all":true},on:{"change":_vm.onChange2},scopedSlots:_vm._u([{key:"children",fn:function({
              props: { filteredItems, selectedKeys },
              on: { itemSelectAll, itemSelect },
            }){return [_c('a-table',{attrs:{"row-selection":_vm.getRowSelection({
                  selectedKeys,
                  itemSelectAll,
                  itemSelect,
                }),"columns":_vm.columns,"data-source":filteredItems,"size":"small","custom-row":({ key }) => ({
                  on: {
                    click: () => {
                      itemSelect(key, !selectedKeys.includes(key));
                    },
                  },
                })}})]}}])})],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.save}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }